import './Services.css'

export default function Services() {
  return (
    <section className="inner-page" style={{marginTop: '100px'}}>
      <div className="container">
        <div className="row featurette">
          <div className="col-md-7">
            <h1 className="display-5 fw-bold lh-1 mb-3">Soluções em Infraestrutura <span className="text-muted">como código.</span>
            </h1>
            <p>Traga sua infraestrutura para avaliarmos a melhor solução para seu negócio. Podemos te ajudar com
              consultoria em <a href="/technology/gitlab">esteiras de entregas</a>, do desenvolvimento, testes até <a href="/technology/gitlab">deploy do seu software de forma automatizada</a>.</p>

            <p><span>Com esteira completa</span> iniciando no desenvolvimento até entrega do software, passando por testes e seguindo
              padrões, podemos fazer com que se tenha muito mais produtividade e acertividade no que sua aplicação deve
              fazer.</p>
          </div>

          <div className="col-md-5 mx-auto mt-auto mb-auto">
            <img className="img-fluid" src="/assets/img/services/development.svg" alt="" />
          </div>
        </div>

        <hr className="featurette-divider" />

        <div className="row featurette">
          <div className="col-md-5 mx-auto mt-auto mb-auto">
            <img className="img-fluid" src="/assets/img/services/code.svg" alt="" />
          </div>

          <div className="col-md-7">
            <h1 className="display-5 fw-bold lh-1 mb-3">Consultoria <span className="text-muted">DevOps</span></h1>
            <p>O DevOps integra a área de desenvolvimento às operações e age na automatização da governança de TI. Entre
              outros benefícios, o framework encurta o ciclo de desenvolvimento otimizando tempo e dinheiro.</p>

            <ul>
              <li><span>CI/CD</span>: <a href="/technology/gitlab">Gitlab</a>, <a href="/technology/aws">AWS (Code Pipeline, Code Build e Code Repository)</a></li>
              <li><span>Infraestrutura como código</span>: <a href="/technology/terraform">Terraform</a> e Cloudformation</li>
              <li><span>Gerenciamento de Configuração</span>: <a href="/technology/ansible">Ansible</a> e Puppet</li>
              <li><span>Containers</span>: <a href="/technology/docker">Docker</a> e orquestração com Openshift e
                <a href="/technology/kubernetes"> Kubernetes</a></li >
              <li><span>Cloud Providers</span>: <a href="/technology/aws">Amazon Web Services</a> e Google Cloud
                Provider</li >
            </ul >
          </div >

        </div >

        <hr className="featurette-divider" />

        <div className="row featurette">
          <div className="col-md-7">
            <h1 className="display-5 fw-bold lh-1 mb-3">Treinamentos sob demanda <span className="text-muted">conforme sua
              necessidade</span></h1>
            <p>Temos treinamentos em diversas áreas da tecnologia, desde infraestrutura até desenvolvimento, converse
              conosco para maiores detalhes de quais assuntos temos mais afinidade e com isso conseguir direcionar você
              ou sua empresa.</p>
          </div>

          <div className="col-md-5 mx-auto mt-auto mb-auto">
            <img className="img-fluid" src="/assets/img/services/training.svg" alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}
