// import About from './Home/About/About';
import Clients from './Home/Clients/Clients';
import Main from './Home/Main/Main';
import WhyUs from './Home/WhyUs/WhyUs';
import Services from './Home/Services/Services'
import LetsTalk from './Home/LetsTalk/LetsTalk';
import Technologies from './Home/Technologies/Technologies';
import { Contact } from './Home/Contact/Contact';
import Team from './Home/Team/Team'

export default function Home() {
  return (
    <div className="Home">
      <Main />
      <WhyUs />
      <Services />
      <Clients />
      <LetsTalk />
      <Technologies />
      <Team />
      <Contact />
    </div>
  );
}
