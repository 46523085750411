export default function Courses({ data }) {


  return (
    <>
      <section id="courses" className="courses section-bg">
        <h2 className="text-center">Conteúdo programático</h2>
        <h4 className="text-center">
          <p>Instrutor: {data.instructor}</p>
        </h4>
        <div className="container">
          <div className="row">
            {
              data.topics.map((attr, index) => {
                return (
                  <>
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12"></div>
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                      <div className="accordion" id="accordionFlushExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={"header-" + (index + 1)}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={"#collapse-" + (index + 1)}
                              aria-expanded="false"
                              aria-controls={"collapse-" + (index + 1)}>
                              <div className="container">
                                <div className="row">
                                  <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12">
                                    <span
                                      className="badge text-bg-primary">Módulo {index + 1}
                                    </span> {attr.name}
                                  </div>

                                  <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12">
                                    <span
                                      className="text-right">
                                    </span> {attr.subtopics.length} aulas

                                  </div>
                                </div>
                              </div>
                            </button>
                          </h2>

                          <div
                            id={"collapse-" + (index + 1)}
                            className="accordion-collapse collapse"
                            aria-labelledby={"header-" + (index + 1)}
                            data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              {
                                attr.subtopics.map((subtopics, index) => {
                                  return (
                                    <p class="list-group-item"><span class="badge text-bg-secondary">Aula {index + 1}</span> {subtopics}</p>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12"></div>
                  </>
                )
              })
            }
          </div>
        </div>
      </section>
    </>
  )
}
