import Tool from "../../Components/Tool"

export const informations = {
  id: "terraform",
  title: "Terraform",
  subtitle: "Infraestrutura como código (Infra as Code)",
  titleDescription: "Infraestrutra como código utilizando Terraform",
  description: [
          "O Terraform permite construir, evoluir e versionar sua infraestrutura em cloud, seja ela pública ou privada, através de código. Permitindo agilidade nas entregas dos times contribuindo para a cultura DevOps, permitindo a fácil inspeção de todo o ambiente e garantindo rastreabilidade dos recursos em nuvem, gerando previsibilidade das mudanças e seus impactos no ambiente.",
          "Terraform usa linguagem declarativa fazendo com que você apenas diga qual o estado que sua infraestrutura deve estar, bem ao contrário em linguagens de programação onde é necessário fazer passo por passo para realizar uma determinada tarefa. Terraform faz com que seu provedor de cloud entenda dependências entre sí para criar e/ou destruir recursos na ordem correta."
  ],
  cardsTitle: "Benefícios de utilizar Terraform em sua Infraestrutura",
  resources: [
    {
      icon: "bx bx-run",
      title: "Agilidade",
      text: "Faça mudanças incrementais em seu Software em produção várias vezes ao dia"
    },
    {
      icon: "bx bx-box",
      title: "Entrega rápida",
      text: "Sem dúvidas um dos pilares do CI/CD, entrega rápida de novos releases de seu Software"
    },
    {
      icon: "bx bx-dollar",
      title: "Redução de custos",
      text: "Outro pilar forte que todos amam, evite repetições para testes, build e deployment, Gitlab é feito para isso"
    },
    {
      icon: "bx bxs-user-check",
      title: "Integração do time",
      text: "CI/CD com Gitlab faz com que seu time em um único local com total rastreio de código sendo desenvolvido"
    }
  ]
}

export default function Terraform() {
  return (
    <Tool
      data={informations}
    />
  )
}
