import Courses from "../../Components/Courses"
import Tool from "../../Components/Tool"
import { informations } from "../Tools/Terraform"

export const courseDetails = {
  id: "terraform",
  title: "Terraform",
  details: [
    {
      day: "04/05/2023",
      duration: 8,
    },
    {
      day: "05/05/2023",
      duration: 8,
    },
    {
      day: "09/06/2023",
      duration: 4,
    }
  ],
  seats: 30,
  seatsSold: 28,
  seatsToStart: 20,
  certicate: true,
  formats: [
    "Presencial",
  ],
  lotes: [
    {
      name: "Lote 1",
      price: "350.00",
      seats: 20,
      benefits: [
        "Caneca",
        "Camiseta",
        "Lista Discussão",
        "Grupo Telegram",
        "Apostila Impressa",
      ],
    },
    {
      name: "Lote 2",
      seats: 10,
      price: "450.00",
      benefits: [
        "Caneca",
        "Camiseta",
        "Lista Discussão",
        "Grupo Telegram",
        "Apostila Impressa",
      ],
    },
  ],
  instructor: "Rafael Dutra",
  subtitle: "Infraestrutura como código (Infra as Code)",
  topics: [
    {
      name: "Infraestrutura como código",
      day: 1,
      subtopics: [
        "Quais os motivos de adotar Iac (Infrastructure as Code)",
        "Imutabilidade de Infraestrutura",
        "Importância do versionamento de código",
        "Code Review"
      ]
    },
    {
      name: "Introdução e instalando Terraform",
      day: 1,
      subtopics: [
        "Introdução ao terraform",
        "O que é o Terraform?",
        "Terraform versus ferramentas dos Providers",
        "Documentação oficial e como utilizar de maneira eficiente",
        "Instalando Terraform no seu Sistema"
      ]
    },
    {
      name: "O básico do Terraform",
      day: 1,
      subtopics: [
        "Hashicorp Language",
        "Tipos de variáveis",
        "Conhecendo a estrutura de comandos",
      ]
    },
    {
      name: "Trabalhando com AWS",
      day: 2,
      subtopics: [
        "Criando uma conta na AWS gratuitamente",
        "Conhecendo o ambiente AWS",
        "VPC (Virtual Private Cloud)",
        "Subnets",
        "Internet Gateways",
        "EC2 (Elastic Compute Cloud)",
      ]
    },
    {
      name: "Criando primeiros recursos com Terraform",
      subtopics: [
        "Configurando credenciais para utilizarmos Terraform",
        "Criando o provider no Terraform",
        "Criando a primeira variável",
        "Criando o primeiro código para EC2",
        "Criando o primeiro código para EBS",
        "Criando uma regra para Security Group",
        "Anexando EBS para EC2",
        "Instalando manualmente um servidor Web",
        "Testando a solução",
        "Anexando Security Group para EC2",
      ]
    },
    {
      name: "Conhecendo módulos",
      subtopics: [
        "O que são módulos",
        "Módulos públicos utilizando https://registry.terraform.io",
        "Como utilizar módulos públicos"
      ]
    },
    {
      name: "Escrevendo módulos",
      subtopics: [
        "Estrutura de um módulo",
        "Escrevendo um módulo para VPC",
        "Versionando o módulo",
        "Escrevendo um módulo para EC2",
        "Versionando o módulo",
        "Adicionando outputs",
        "Reescrevendo o componente de EC2 para utilizar módulo"
      ]
    },
    {
      name: "Trabalhando com diferentes environments",
      subtopics: [
        "O que são Worspaces do Terraform",
        "Como criar um código multi environment"
      ]
    },
    {
      name: "Utilizando backends",
      subtopics: [
        "O que são backends",
        "Como utilizar",
        "Criando um Bucket para centralização",
        "Movendo o terraform.tfstate para o backend"
      ]
    }
  ]
}

export default function Terraform() {
  var totalDuration = 0
  var sizeSubtopics = 0
  const sizeTopics = courseDetails.topics.length

  courseDetails.details.map((course) => {
    totalDuration = totalDuration + course.duration
  })

  courseDetails.topics.map((attr) => {
    sizeSubtopics = sizeSubtopics + attr.subtopics.length
  })

  const compareDates = (d1, d2) => {
    let date1 = new Date(d1).toLocaleDateString('en-GB');
    let date2 = new Date(d2).toLocaleDateString('en-GB');

    if (date1 >= date2) {
      return true
    }

    return false
  }

  return (
    <>
      <Tool
        data={informations}
        cardsEnabled={false}
      />

      <section className="inner-page">
        <div className="container text-center">
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"></div>

            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <h3><span className="badge bg-warning">{totalDuration}h</span></h3>
              <h3><span className="badge bg-warning">{sizeTopics} Tópicos</span></h3>
              <h3><span className="badge bg-warning">{sizeSubtopics} Aulas</span></h3>
            </div>

            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <h3>
                {
                  courseDetails.details.map((attr) => {
                    return (
                      <>
                        {
                          compareDates(attr.day, new Date().toLocaleDateString('en-GB'))
                            ?
                            <span
                              className="badge bg-primary">{attr.day} <span class="badge rounded-pill bg-danger">{attr.duration} horas</span>
                            </span>
                            :
                            <span
                              className="badge bg-secondary text-decoration-line-through">{attr.day} <span class="badge rounded-pill bg-secondary text-decoration-line-through">{attr.duration} horas</span>
                            </span>
                        }
                      </>
                    )
                  })
                }
              </h3>
            </div>
          </div>
        </div>
      </section>

      <Courses
        data={courseDetails}
      />

      <section id="pricing" className="pricing">
        <div className="container" data-aos="fade-up">

          <div className="section-title">
            <h2>Preço</h2>
            <p className="text-left">Estamos trabalhando com dois lotes para fechamento o quanto antes da turma, portanto faça sua inscrição pelo Sympla neste link ou clicando nos links abaixo.</p>
          </div>

          <div className="row">
            <div className="col-lg-2"></div>

            {
              courseDetails.lotes.map((lote, index) => {
                var seatsAvailable = false
                var seats = lote.seats

                if (index == 0) {
                  if (seats - courseDetails.seatsSold >= 1) {
                    var seatsAmount = seats - courseDetails.seatsSold
                    var seatsAvailable = true
                  } else {
                    var seatsAmount = 0
                  }
                } else {
                  var seatsAmount = seats

                  if (!seatsAvailable && (courseDetails.seatsSold - seats) >= seats) {
                    var seatsAmount = (courseDetails.seats - courseDetails.seatsSold)
                  }
                }

                return (
                  <div key={index} className="col-lg-4">
                    <div className="box featured">
                      <h3>{lote.name}
                        {
                          seatsAmount < 1
                            ?
                            <span class="badge text-bg-danger ms-2"> Encerrado</span>
                            :
                            <span class="badge text-bg-info ms-2"> {seatsAmount} disponíveis</span>
                        }
                      </h3>

                      <h4><sup>R$</sup>{lote.price}</h4>
                      <ul>
                        {
                          lote.benefits.map((attr) => {
                            return (
                              <li key={index}><i className="bx bx-check"></i>{attr}</li>
                            )
                          })
                        }
                      </ul>
                      <a href="#" className="buy-btn">Me inscrever</a>
                    </div>
                  </div>
                )
              })
            }

            <div className="col-lg-2"></div>

          </div>
        </div>
      </section>

      <section id="contact" className="contact section-bg">
        <div className="container-fluid" data-aos="fade-up">
          <div className="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch">
            <iframe title="google-maps"
              src="https://maps.google.com/maps?q=-29.4483332,-51.9479219&hl=es&z=14&amp;output=embed"
              style={{ frameBorder: "0", border: '0', width: '100%', height: '335px' }} allowFullScreen></iframe>
          </div>
        </div>
      </section>
    </>
  )
}
