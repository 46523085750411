import Tool from "../../Components/Tool"

const data = {
  id: "ansible",
  title: "Ansible",
  subtitle: "Gerência de configuração",
  titleDescription: "Gerenciamento de configuração com Ansible",
  description: [
    "Ansible é uma plataforma de automação simples de usar, robusta e totalmente de código aberto. Ansible está disponível gratuitamente e é executado em Linux, Mac ou BSD. Além da oferta gratuita, a Ansible também possui um produto empresarial chamado Ansible Tower. A plataforma Ansible usa SSH e também foi escrito em Python. Contudo, existem alguns outros requisitos, por exemplo, se você quiser fazer algo relacionado ao git em uma máquina remota, primeiro um pacote git deve ser instalado na máquina remota.",
    "No contexto de servidores, o gerenciamento de configuração também é geralmente referido como Automação de TI ou Orquestração de Servidor. Ambos os termos destacam os aspectos práticos do gerenciamento de configuração e a capacidade de controlar vários sistemas a partir de um servidor central.",
    "O Ansible acompanha o estado de recursos em sistemas gerenciados, para evitar a repetição de tarefas que foram executadas antes. Se um pacote já foi instalado, ele não tentará instalá-lo novamente."
  ],
  cardsTitle: "Benefícios de utilizar Ansible em sua Infraestrutura",
  resources: [
    {
      icon: "bx bx-folder",
      title: "Versionamento",
      text: "Usar um sistema de controle de versão para acompanhar quaisquer alterações em sua infraestrutura"
    },
    {
      icon: "bx bx-select-multiple",
      title: "Reuso",
      text: "Reutilizar scripts de provisionamento para vários ambientes de servidor, como desenvolvimento, teste e produção"
    },
    {
      icon: "bx bx-duplicate",
      title: "Replicação",
      text: "Simplificar o processo de replicação de servidores, o que facilita a recuperação de erros críticos"
    },
    {
      icon: "bx bx-message-alt-detail",
      title: "Idempotência",
      text: "Esse é um conceito muito importante quando se diz que o Ansible usa uma linguagem declarativa"
    }
  ]
}

export default function Ansible() {
  return (
    <Tool
      data={data}
    />
  )
}
