import { Partner, PartnerModal } from "../../../Components/Partner";
import './Team.css'

var fullYear = new Date().getFullYear()

const data = [
  {
    id: "rafael-dutra",
    active: true,
    name: "Rafael Dutra",
    city: "Estrela/RS",
    jobTitle: "DevOps Engineer SR | SRE",
    avatar: "/assets/img/team/rafael-dutra.jpeg",
    jobDescription: `Cloud Computing, Orquestração de Containers, CI/CD, e Infraestrutura como Código.`,
    links: {
      linkedin: 'rafaeldutra',
      gitlab: 'raffaeldutra',
      github: 'raffaeldutra',
    }
  },
  {
    id: "jalis-diehl",
    active: false,
    name: "Jalis Diehl",
    city: "Lajeado/RS",
    jobTitle: "DevOps Engineer SR",
    avatar: "/assets/img/team/jalis-diehl.png",
    jobDescription: `Cloud Computing, Orquestração de Containers, CI/CD e Infraestrutura como Código.`,
    links: {
      linkedin: 'jalisdiehl',
      gitlab: 'jalisdiehl',
      github: 'jalisdiehl',
    }
  },
  {
    id: "lucas-andrade",
    active: true,
    name: "Lucas Andrade",
    city: "São Bernardo do Campo/SP",
    jobTitle: "DevOps Engineer PL",
    avatar: "/assets/img/team/lucas-andrade.jpeg",
    jobDescription: ` Cloud Computing, Orquestração de Containers, CI/CD e Infraestrutura como Código.`,
    links: {
      linkedin: 'slucasandrade',
      gitlab: 'slucasandrade',
      github: 'slucasandrade',
    }
  },
  {
    id: "diego-maia",
    active: false,
    name: "Diego Maia",
    city: "Estrela/RS",
    jobTitle: "DevOps Engineer SR | SRE",
    avatar: "/assets/img/team/diego-maia.jpeg",
    jobDescription: `Cloud Computing, Orquestração de Containers, CI/CD e Infraestrutura como Código.`,
    links: {
      linkedin: 'diegosmaia',
      gitlab: 'dsmaia',
      github: '2dsm',
    }
  },
  {
    id: "natan-schultz",
    active: true,
    name: "Natan Schultz",
    city: "Amsterdam/NL",
    jobTitle: "System Engineer SR",
    avatar: "/assets/img/team/natan-schultz.jpeg",
    jobDescription: `Engenheiro de Sistemas, Orquestração de Containers, CI/CD, e Infraestrutura como Código.`,
    links: {
      linkedin: 'natanschultz',
      gitlab: 'natanschultz',
      github: 'natanschultz',
    }
  },
  {
    id: "cleomar-schmitzhaus",
    active: false,
    name: "Cleomar Schmitzhaus",
    city: "Teutônia/RS",
    jobTitle: "Devops Engineer Jr",
    avatar: "/assets/img/team/cleomar-schmitzhaus.jpeg",
    jobDescription: `Infraestrutura raiz, Automação de Infraestrutura e Containers.`,
    links: {
      linkedin: 'cleomar',
      gitlab: 'cleomar',
      github: 'cleomar',
    }
  },
  {
    id: "mateus-roveda",
    active: false,
    name: "Mateus Roveda",
    city: "Lajeado/RS",
    jobTitle: "DevOps Engineer PL",
    avatar: "/assets/img/team/mateus-roveda.jpeg",
    jobDescription: `SysAdmin com cultura em DevOps, Desenvolvedor e entusiasta Cyber Security.`,
    links: {
      linkedin: 'mateusrovedaa',
      gitlab: 'mateusrovedaa',
      github: 'mateusrovedaa',
      youtube: 'roveeb',
      stackoverflow: '18825609',
      twitter: 'mateusrovedaa'
    }
  },
  {
    id: "fernanda-delavald",
    active: false,
    name: "Fernanda Delavald",
    city: "Lajeado/RS",
    jobTitle: "Frontend Engineer",
    avatar: "https://media-exp1.licdn.com/dms/image/C4D03AQE6iB-7U-7kDQ/profile-displayphoto-shrink_200_200/0/1637022816512?e=1669852800&v=beta&t=EWOPWAy0l38wJQh1VtqGpUb_y9Rw2vXohz-Hskhbpms",
    jobDescription: `${fullYear - 2021} anos de experiência em TI. Iniciando a carreira.`,
    links: {
      linkedin: 'fernanda-delavald',
      gitlab: 'fernanda-delavald',
      github: 'fernanda-delavald',
    }
  },

]

export default function Team() {
  return (
    <>
      <section id="team" className="team section-bg">
        <div className="container aos-init aos-animate" data-aos="fade-up">

          <div className="section-title">
            <h2>Nosso time</h2>
            <p>Conheça um pouco sobre nós, siga nossas redes (aquelas que transformam café em código).</p>
          </div>

          <div className="row">
            <Partner
              data={data}
            />
          </div>
        </div>
      </section>

      <PartnerModal
        data={data}
      />
    </>
  )
}
