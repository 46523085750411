import Tool from "../../Components/Tool"

const data = {
  id: "aws",
  title: "AWS",
  subtitle: "Amazon Web Services",
  titleDescription: "Computação em Nuvem com AWS",
  description: [
    "A Amazon Web Services (AWS) é a plataforma de nuvem mais adotada e mais abrangente do mundo, oferecendo mais de 200 serviços completos de datacenters em todo o mundo. Milhões de clientes, incluindo as startups de crescimento mais rápido, grandes empresas e os maiores órgãos governamentais, estão usando a AWS para reduzirem seus custos, ficarem mais ágeis e inovarem mais rapidamente.",
    "A AWS tem a mais extensa infraestrutura de nuvem global. Nenhum outro provedor de nuvem oferece tantas regiões com múltiplas Zonas de Disponibilidade conectadas por redes altamente redundantes com baixa latência e alta taxa de transferência. A AWS tem 81 Zonas de Disponibilidade em 25 regiões geográficas em todo o mundo e anunciou planos para mais 21 Zonas de Disponibilidade e mais 7 regiões da AWS na Austrália, Emirados Árabes Unidos (EAU), Espanha, Índia, Indonésia, Israel, e Suíça. O modelo de região e Zona de Disponibilidade da AWS foi reconhecido pela Gartner como a abordagem recomendada para a execução de aplicações empresariais que exigem alta disponibilidade"
  ],
  cardsTitle: "Em que podemos te ajudar com AWS",
  resources: [
    {
      icon: "bx bx-desktop",
      title: "Capacidade computacional",
      text: "Facilitar a Computação em Nuvem em escala para Desenvolvedores e Administrador de Sistemas"
    },
    {
      icon: "bx bx-chip",
      title: "Armazenamento",
      text: "Dentro da AWS, existem vários tipos de soluções em armazenamento de dados. Podemos te auxiliar quais das diferentes soluções adotar"
    },
    {
      icon: "bx bx-terminal",
      title: "Replicação",
      text: "Simplificar o processo de replicação de servidores, o que facilita a recuperação de erros críticos"
    },
    {
      icon: "bx bx-message-square-detail",
      title: "Economia",
      text: "Você paga somente pelo que usa, sem contratos de longo prazo ou compromissos prévios."
    },
  ]
}

export default function AWS() {
  return (
    <Tool
      data={data}
    />
  )
}
