import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Home from './Pages/Home';
import Ansible from './Pages/Tools/Ansible'
import Kubernetes from './Pages/Tools/Kubernetes'
import AWS from './Pages/Tools/AWS'
import Docker from './Pages/Tools/Docker'
import Gitlab from './Pages/Tools/Gitlab'
import Terraform from './Pages/Tools/Terraform'
import Services from './Pages/Services/Services'
import Web from './Pages/Web/Web'
import Courses from './Pages/Courses/Courses'
import CourseTerraform from './Pages/Courses/Terraform'

export default function Routing() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/technology/ansible" element={<Ansible />} />
        <Route path="/technology/kubernetes" element={<Kubernetes />} />
        <Route path="/technology/aws" element={<AWS />} />
        <Route path="/technology/docker" element={<Docker />} />
        <Route path="/technology/terraform" element={<Terraform />} />
        <Route path="/technology/gitlab" element={<Gitlab />} />
        <Route path="/services" element={<Services />} />
        <Route path="/web" element={<Web />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/courses/terraform" element={<CourseTerraform />} />
      </Routes>
    </Router>
  )
}
