const technologies = [
  {
    name: "aws",
    link: "technology/aws",
  },
  {
    name: "ansible",
    link: "technology/ansible",
  },
  {
    name: "kubernetes",
    link: "technology/kubernetes",
  },
  {
    name: "terraform",
    link: "technology/terraform",
  },
  {
    name: "docker",
    link: "technology/docker",
  },
   {
    name: "gitlab",
    link: "technology/gitlab",
  },
]

export default function Main() {
  return (
    <>
      <section id="start" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up"
              data-aos-delay="200">
              <h1>Soluções em Tecnologia para o seu negócio</h1>
              <h2>Somos uma empresa especializada em serviços em Computação em Nuvem, como podemos te ajudar?</h2>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <a href="#about" className="btn-get-started scrollto">Nos conheça</a>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 start-img" data-aos="zoom-in" data-aos-delay="200">
              <img src="assets/img/page/start.svg" className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section id="clients" className="clients section-bg">
        <div className="container">
          <div className="row" data-aos="zoom-in">
            {
              technologies.map((attr, index) => {
                return (
                  <div key={index} className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-12 d-flex align-items-center justify-content-center">
                    <a href={attr.link} target="_blank" rel="noopener noreferrer"><img src={"assets/img/technologies/" + attr.name + ".png"} className="img-fluid" alt={attr.name} /></a>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
    </>
  )
}
