import {
  phone
} from '../CompanyData'

function Header() {
  return (
    <header id="header" className="fixed-top header-scrolled">
      <div className="container d-flex align-items-center">
        <h1 className="logo me-auto"><a href="/">APSIS.cc</a></h1>

        <nav id="navbar" className="navbar">
          <ul>
            <li><a href="/" className="nav-link scrollto active">Início</a></li>
            <li><a href="https://blog.apsis.cc" target="_blank" className="nav-link">Artigos</a></li>

            <li className="dropdown"><a><span style={{ color: "#ffffff" }}>Tecnologias</span> <i className="bx bx-caret-down"></i></a>
              <ul>
                <li><a href={"/technology/aws"}>AWS</a></li>
                <li><a href={"/technology/ansible"}>Ansible</a></li>
                <li><a href={"/technology/docker"}>Docker</a></li>
                <li><a href={"/technology/gitlab"}>Gitlab</a></li>
                <li><a href={"/technology/kubernetes"}>Kubernetes</a></li>
                <li><a href={"/technology/terraform"}>Terraform</a></li>
              </ul>
            </li>

            <li><a className="nav-link scrollto" href="#team">Time</a></li>
            <li><a href={"/services"}>Serviços</a></li>
            <a href={'tel:'+ phone} style={{ paddingLeft: '20px', paddingRight: '1px' }}>
              <i className='bx bx-phone' style={{ fontSize: '20px' }}></i>
            </a>
            <a href="https://wa.me/message/W4ALVLN2HCWXF1" style={{ padding: '0 1px 0 0' }}>
              <i className='bx bxl-whatsapp' style={{ fontSize: '20px' }}></i>
            </a>
            <a href="mailto:contato@apsis.cc" style={{ padding: '0 1px 0 0' }}>
              <i className='bx bx-mail-send' style={{ fontSize: '20px' }}></i>
            </a>
          </ul>
        </nav>

      </div>
    </header>
  );
}

export default Header;
