import Tool from "../../Components/Tool"

const data = {
  id: "gitlab",
  title: "Gitlab",
  subtitle: "Ciclo completo de desenvolvimento de Software",
  titleDescription: "Computação em Nuvem com AWS",
  description: [
    "Gitlab é uma empresa que desenvolve software para desenvolvedores de software. Gitlab mais de 30 milhões de usuários registrados e uma comunidade ativa com mais de 3 mil contribuidores.",
    "Com Gitlab é possível quebrar aquele velho silo já conhecido de tantos que é o time de desenvolvimento versus time de operações. Ambos agora trabalham junto no melhor ciclo de desenvolvimento e entrega de software utilizando Pipelines (fluxos de build, testes e deploy) para melhor qualidade do desenvolvimento do software como melhor gerenciamento pelo time de operações."
  ],
  cardsTitle: "CI/CD mostra seu valor em inúmeras situações, vejamos algumas",
  resources: [
    {
      icon: "bx bx-run",
      title: "Agilidade",
      text: "Faça mudanças incrementais em seu Software em produção várias vezes ao dia"
    },
    {
      icon: "bx bx-box",
      title: "Entrega rápida",
      text: "Sem dúvidas um dos pilares do CI/CD, entrega rápida de novos releases de seu Software"
    },
    {
      icon: "bx bx-dollar",
      title: "Redução de custos",
      text: "Outro pilar forte que todos amam, evite repetições para testes, build e deployment, Gitlab é feito para isso"
    },
    {
      icon: "bx bxs-user-check",
      title: "Integração do time",
      text: "CI/CD com Gitlab faz com que seu time em um único local com total rastreio de código sendo desenvolvido"
    }
  ]
}

export default function Gitlab() {
  return (
    <Tool
      data={data}
    />
  )
}
