export function Contact() {
  return (
    <section id="contact" className="contact section-bg">
      <div className="container-fluid" data-aos="fade-up">
        <div className="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch">
          <iframe title="google-maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3473.8522860406038!2d-51.96812889999999!3d-29.462323399999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951c6164a91e6e6d%3A0x2febd72a9162cc8!2sR.%20Pinheiro%20Machado%2C%20550%20-%20Centro%2C%20Lajeado%20-%20RS%2C%2095900-078!5e0!3m2!1spt-BR!2sbr!4v1715459160081!5m2!1spt-BR!2sbr"
            style={{ frameBorder: "0", border: '0', width: '100%', height: '335px' }} allowFullScreen></iframe>
        </div>
      </div>
    </section>
  )
}
