import './Footer.css'
import * as CompanyData from '../CompanyData'

const links = [
  {
    name: "AWS",
    link: "aws.com",
  },
  {
    name: "Ansible",
    link: "ansible.com"
  },
  {
    name: "Docker",
    link: "docker.com",
  },
  {
    name: "Gitlab",
    link: "gitlab.com",
  },
  {
    name: "Kubernetes",
    link: "kubernetes.io",
  },
  {
    name: "Terraform",
    link: "terraform.io"
  }
]

const technologies = [
  {
    name: "Computação em Nuvem com AWS",
    link: "technology/aws",
  },
  {
    name: "Gerência de configuração com Ansible",
    link: "technology/ansible",
  },
  {
    name: "Aplicações em containers com Docker",
    link: "technology/docker",
  },
  {
    name: "Integração e entrega contínua",
    link: "technology/gitlab",
  },
  {
    name: "Orquestração de containers Kubernetes",
    link: "technology/kubernetes",
  },
  {
    name: "Infraestrutura como Código com Terraform",
    link: "technology/terraform",
  },
]


export default function Footer() {
  return (
    <div className='Footer'>

      <footer id="footer">
        <div className="footer-top section-bg">
          <div className="container">

            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 mx-auto footer-contact">
                <h3>{CompanyData.companyName}</h3>
                <p>{CompanyData.address}</p>
                <p>{CompanyData.location}, {CompanyData.city} - {CompanyData.country}</p>
                <p><strong>Telefone: </strong>
                  <a href={'tel:' + CompanyData.phone}>
                    {CompanyData.phone}
                  </a>
                </p>
                <p><strong>Email: </strong>
                  <a href={'mailto:' + CompanyData.email}>{CompanyData.email}</a>
                </p>
              </div>

              <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6 footer-links">
                <h4>Sites oficiais</h4>
                <ul>
                  {
                    links.map((attr, index) => {
                      return (
                        <li key={index}>
                          <i className="bx bx-chevron-right"></i>
                          <a
                            className="scrollto"
                            href={"https://" + attr.link} target="_blank" rel="noopener noreferrer">{attr.name}
                          </a>
                        </li>
                      )
                    })
                  }

                </ul>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 footer-links">
                <h4>Soluções para Aplicações e Infraestrutura</h4>
                <ul>
                  {
                    technologies.map((attr, index) => {
                      return (
                        <li key={index}>
                          <i className="bx bx-chevron-right"></i>
                          <a href={attr.link}>{attr.name}</a>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 footer-contact">
                <p>{CompanyData.realName}</p>
                <p>CNPJ: {CompanyData.cnpj}</p>
              </div>
            </div>

          </div>
        </div>

        <div className="container footer-bottom clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-6 col-sm-6 col-sm-6">
                <div className="copyright">
                  Criado durante madrugadas frias do RS com muito <strong><span><i
                    className='bx bxs-coffee-alt'></i></span></strong>
                  para se proteger.
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-sm-6">
                <div className="copyright">
                  © {CompanyData.companyName} soluções em TI | 2018 - {CompanyData.year}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer >
    </div>
  );

}
