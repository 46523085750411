import './Web.css'

export default function Web() {
  return (
    <>
      <section className="tech section-bg">
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 d-flex align-self-center align-items-center justify-content-center">
              <a href="/assets/img/web/js.png">
                <img src="assets/img/web/js.png" alt="" className="img-fluid" />
              </a>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
              <a href="/assets/img/web/bootstrap.png">
                <img src="assets/img/web/bootstrap.png" alt="" className="img-fluid" />
              </a>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
              <a href="/assets/img/web/gitlab.png">
                <img src="assets/img/web/gitlab.png" alt="" className="img-fluid" />
              </a>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
              <a href="/assets/img/web/analytics.png">
                <img src="assets/img/web/analytics.png" alt="" className="img-fluid" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="why">
        <div id="why" className="why">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="section-title">
                  <h2>Por que você precisa de uma WebPage?</h2>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div className="section-title">
                  <figure><img src="assets/img/web/why7.svg" alt="#" /></figure>
                </div>
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12">
                <div className="web_why">
                  <h3>Acesso</h3>
                  <p>
                    Quando alguém ouve o nome da sua empresa e se interessa pelo produto ou serviço é provável que ele busque mais informações na Internet. Se não encontrar nenhuma informação ou referência, pode acabar optando por outra.<span style={{ fontWeight: 'bold' }}> Facilite o acesso</span> dos seus potenciais clientes <span style={{ fontWeight: 'bold' }}>à sua marca e ao que você tem de melhor!</span> Marque presença digital no ramo de atuação do seu negócio.
                  </p>
                  <p>
                    <span style={{ fontWeight: 'bold' }}>O número de empresas com sites dobrou nos últimos anos</span>. Isso anda lado a lado com o aumento do uso de redes como Instagram e Facebook.
                  </p>
                  <h3>Credibilidade</h3>
                  <p>
                    No entanto, <span style={{ fontWeight: 'bold' }}>o diferencial de ter um website é a credibilidade</span> que ele confere ao seu negócio. Uma apresentação profissional em um website estruturado e completo conversa com o público de todos os costumes e idades em qualquer lugar do mundo. Você demonstra não apenas <span style={{ fontWeight: 'bold' }}>ser conhecido</span> mas também verdadeira autoridade <span style={{ fontWeight: 'bold' }}>no ramo</span>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="type-page section-bg">
        <div className="container">
          <div className="row">
            <div className="section-title">
              <h2>Landing Page</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12">
              <div className="type-page-box">
                <p>
                  A Landing Page é uma <span style={{ fontWeight: 'bold' }}>página única</span>, com foco em <span style={{ fontWeight: 'bold' }}>converter uma visita em uma oportunidade de negócio.</span> Em geral não conta com navegação, links externos,menus clicáveis. É objetiva e apresenta informações direto ao ponto.
                </p>
                <p>
                  É destinada a conduzir o visitante a realizar uma ação. Ao interagir com a página, o usuário demonstra sua intenção de busca e assim possibilita um melhor direcionamento por parte do anunciante que deseja fechar bons negócios.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <figure className="figure"><img src="assets/img/web/landingpage.png" className="figure-img img-fluid rounded" alt="#" /></figure>
            </div>
          </div>
        </div>
      </section>

      <section className="type-page">
        <div className="container">
          <div className="row">
            <div className="section-title">
              <h2>Webpage</h2>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">

              <figure className="figure"><img src="assets/img/web/webpage.png" className="figure-img img-fluid rounded" alt="#" /></figure>
            </div>
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12">
              <div className="type-page-box">
                <p>
                  A WebPage é uma <span style={{ fontWeight: 'bold' }}>plataforma completa</span> que apresenta sua empresa com informações diversas, podendo incluir serviços, contato, produtos, cases e mais. Centraliza as informações da empresa em um só lugar.
                </p>
                <p>
                  Oferece menus clicáveis e pode apresentar páginas extras. O foco é institucional. A WebPage se torna um portal seguro que serve de referência para o consumidor sanar dúvidas e ter uma visão do todo a respeito do produto ou serviço.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="renovate section-bg">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="section-title">
                <h2>Renove seu Site</h2>
              </div>
            </div>

            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="card-deck mb-3">
                <div className="card mb-4 shadow-sm">
                  <div className="web-section-title">
                    <h3>Atualize seu site</h3>
                  </div>
                  <div className="card-body">
                    <p>Dê à sua página um visual moderno e funcional. Que se comunique com o público de forma assertiva. E seja:
                    </p>
                    <ul className="list-unstyled">
                      <li><span class="badge rounded-pill text-bg-secondary">1</span> Atrativo ao visitante</li>
                      <li><span class="badge rounded-pill text-bg-secondary">2</span> De fácil acesso</li>
                      <li><span class="badge rounded-pill text-bg-secondary">3</span> Ponto de referência</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="card mb-4 shadow-sm">
                <div className="web-section-title">
                  <h3>Implemente Funcionalidades</h3>
                </div>
                <div className="card-body">
                  <p>
                    Com o avanço da tecnologia há várias funcionalidades para aperfeiçoar a experiência do usuário:
                  </p>
                  <ul className="list-unstyled">
                    <li><span class="badge rounded-pill text-bg-secondary">1</span> Proporcionar interação direta</li>
                    <li><span class="badge rounded-pill text-bg-secondary">2</span> Acompanhar número de acessos</li>
                    <li><span class="badge rounded-pill text-bg-secondary">3</span> Captar perfil de acesso</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="metrify">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="section-title">
                <h2>Meça o Desempenho</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="card-deck mb-3">
                <div className="card mb-4 shadow-sm">
                  <div className="web-section-title">
                    <h3>Métrica de Acessos</h3>
                  </div>
                  <div className="card-body">
                    <p>
                      Conhecer o perfil do visitante é uma vantagem estratégica. O Google Analytics realiza isso com métricas:
                    </p>
                    <ul className="list-unstyled">
                      <li><span class="badge rounded-pill text-bg-secondary">1</span> Monitorando desempenho</li>
                      <li><span class="badge rounded-pill text-bg-secondary">2</span> Relatando acessos</li>
                      <li><span class="badge rounded-pill text-bg-secondary">3</span> Rankeando os destaques</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="card mb-4 shadow-sm">
                <div className="web-section-title">
                  <h3>Produto de Destaque</h3>
                </div>
                <div className="card-body">
                  <p>
                    Monitorar as seções visitadas pode revelar o produto pelo qual a sua empresa é reconhecida. Além disso:
                  </p>
                  <ul className="list-unstyled">
                    <li><span class="badge rounded-pill text-bg-secondary">1</span> Revela potencialidades</li>
                    <li><span class="badge rounded-pill text-bg-secondary">2</span> Permite conhecer performance</li>
                    <li><span class="badge rounded-pill text-bg-secondary">3</span> Proporciona agir em melhorias</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hosting section-bg">
        <div className="container">
          <div id="hosting" className="hosting">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="section-title">
                  <h2>Hospedagem, e agora?</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="card mb-3">
                  <div class="card-body">
                    <h5 class="card-title text-center">Pro</h5>
                    <h1 className="card-title pricing-card-title text-center">R$ 15,00
                      <small className="text-muted">/ mês</small>
                    </h1>
                    <p class="card-text">
                      <ul className="list-unstyled">
                        <li>20 usuários</li>
                        <li>10 GB de armazenamento</li>
                        <li>Suporte por email prioritário</li>
                        <li>Acesso ao centro de ajuda</li>
                      </ul>
                    </p>
                    <button type="button" className="btn btn-secondary">Cadastre-se</button>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="card mb-3">
                  <div class="card-body">
                    <h5 class="card-title text-center">Pro</h5>
                    <h1 className="card-title pricing-card-title text-center">R$ 15,00
                      <small className="text-muted">/ mês</small>
                    </h1>
                    <p class="card-text">
                      <ul className="list-unstyled">
                        <li>20 usuários</li>
                        <li>10 GB de armazenamento</li>
                        <li>Suporte por email prioritário</li>
                        <li>Acesso ao centro de ajuda</li>
                      </ul>
                    </p>
                    <button type="button" className="btn btn-secondary">Cadastre-se</button>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="card mb-3">
                  <div class="card-body">
                    <h5 class="card-title text-center">Pro</h5>
                    <h1 className="card-title pricing-card-title text-center">R$ 15,00
                      <small className="text-muted">/ mês</small>
                    </h1>
                    <p class="card-text">
                      <ul className="list-unstyled">
                        <li>20 usuários</li>
                        <li>10 GB de armazenamento</li>
                        <li>Suporte por email prioritário</li>
                        <li>Acesso ao centro de ajuda</li>
                      </ul>
                    </p>
                    <button type="button" className="btn btn-secondary">Cadastre-se</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="questions">
        <div className="container">
          <div id="questions" className="questions">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="section-title">
                  <h2>Dúvidas Frequentes</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <img src="assets/img/web/questions.svg" className="img-fluid" alt="#" />
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> 01 Posso divulgar o site nas redes sociais ?
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <strong>Sim! Você pode divulgar o site da empresa nas redes sociais que ela já tem.</strong> Bastam poucos cliques para que seus seguidores possam conhecer mais essa novidade.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> 02 Como sei quantos acessos o site teve?
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <strong>Existe uma plataforma chamada Google Analytics.</strong> Com ela você pode saber até o perfil dos acessos e assim planejar quais conteúdos destacar e quais incluir.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> 03 Posso mudar a página quando quiser?
                      </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <strong>Pode!</strong> Seu site pode ser modificado quando você quiser atualizar as informações sobre o negócio, os produtos, serviços, e conforme novidades forem surgindo.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service section-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2>Serviços</h2>
              </div>
            </div>
          </div>
          <div className="row service-box">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
              <ul className="bx-ul">
                <li><i className='bx bx-layout bx-sm'></i> Criação de WebPages</li>
                <li><i className='bx bx-check-shield bx-sm'></i> SSL Gratuito</li>
              </ul>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
              <ul className="bx-ul">
                <li><i className='bx bx-line-chart bx-sm'></i> Métrica de acessos</li>
                <li><i className='bx bx-user bx-sm'></i> Perfil de acessos</li>
              </ul>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
              <ul className="bx-ul">
                <li><i className='bx bx-support bx-sm'></i> Suporte integral</li>
                <li><i className='bx bx-time bx-sm'></i> Uptime Garantido</li>
              </ul>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
              <ul className="bx-ul">
                <li><i className='bx bx-code-alt bx-sm'></i> Manutenção de Sites</li>
                <li><i className='bx bx-home bx-sm'></i> Hospedagem de Sites</li>
              </ul>
            </div>
          </div>
        </div>
      </section>


      <section className="inner-page">
        <div className="container">
          <div className="row featurette">
            <div className="col-md-12">
              <div className="section-title">
                <h2>Nossos diferenciais</h2>
              </div>
            </div>

            <div className="col-md-7">
              <h1 className="display-5 fw-bold lh-1 mb-3">Colaboração com o <span className="text-muted">cliente</span></h1>
              <p>Acreditamos que <span style={{ fontWeight: 'bold' }}>atuar em conjunto com o cliente </span> é a chave para alcançar os resultados almejados. Esse princípio norteia nosso trabalho <span style={{ fontWeight: 'bold' }}>acima da negociação de contratos</span> e promove maior interação entre todos os interessados.</p>
            </div>

            <div className="col-md-5 mx-auto mt-auto mb-auto">
              <figure className="figure"><img src="assets/img/web/differentials2.svg" className="figure-img img-fluid rounded" alt="#" /></figure>
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-md-5 mx-auto mt-auto mb-auto">
              <figure className="figure"><img src="assets/img/web/diff2.svg" className="figure-img img-fluid rounded" alt="#" /></figure>
            </div>

            <div className="col-md-7">
              <h1 className="display-5 fw-bold lh-1 mb-3">Expertise no <span className="text-muted">mercado</span></h1>
              <p>
                Temos <span style={{ fontWeight: 'bold' }}>ampla experiência</span> no mercado. As situações ao longo da nossa trajetória nos permitem saber o caminho pelo qual lhe guiar. Um <span style={{ fontWeight: 'bold' }}>acompanhamento profissional</span> é o que a sua empresa precisa para se manter no topo.
              </p>
            </div >
          </div >

          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-md-7">
              <h1 className="display-5 fw-bold lh-1 mb-3">Contato <span className="text-muted">direto</span></h1>
              <p>Você fala <span style={{ fontWeight: 'bold' }}>direto conosco</span>, sem intermediários. Através de um suporte dedicado, ouvimos a sua demanda, traçamos a estratégia em conjunto e oferecemos o suporte necessário. Na medida certa para o que você quer e precisa.
              </p>
            </div>

            <div className="col-md-5 mx-auto mt-auto mb-auto">
              <figure className="figure"><img src="assets/img/web/differentials6.png" className="figure-img img-fluid rounded" alt="#" /></figure>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
