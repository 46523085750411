import Tool from "../../Components/Tool"

const data = {
  id: "kubernetes",
  title: "Kubernetes",
  subtitle: "Orquestração de containers",
  titleDescription: "Orquestrando containers com Kubernetes",
  description: [
    "Kubernetes é uma plataforma de código aberto em que se automatiza a implantação, dimensiona e gerencia aplicativos em container, permitindo maior produtividade sem que os demais processos do sistema fiquem inativos ou sofram falhas.",
    "O Google tornou Kubernetes um projeto de código aberto em 2014 combinando mais de 15 anos de experiência do Google em execução containers em grande escala."
  ],
  cardsTitle: "Como podemos ajudar a gerenciar projetos utilizando Kubernetes",
  resources: [
    {
      icon: "bx bx-run",
      title: "Produtividade",
      text: "Com Kubernetes muitos deployments de sua aplicação podem acontecer por dia e não mais apenas em semanas ou meses"
    },
    {
      icon: "bx bx-box",
      title: "Microsserviços",
      text: "Os microsserviços são fracamente acoplados, distribuídos, elásticos e livres: as aplicações são divididas em partes menores e independentes"
    },
    {
      icon: "bx bx-transfer",
      title: "Portabilidade",
      text: "Execute em Ubuntu, RHEL, CoreOS, localmente e em nuvens públicas e privadas"
    },
    {
      icon: "bx bxs-user-check",
      title: "Autocorreção",
      text: "O Kubernetes reinicia, substitui e elimina os contêineres que não respondem à verificação de integridade definida pelo usuário"
    },
  ]
}

export default function Kubernetes() {
  return (
    <Tool
      data={data}
    />
  )
}
