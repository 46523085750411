import Tool from "../../Components/Tool"

const data = {
  id: "docker",
  title: "Docker",
  subtitle: "Isolamento de processos em Containers",
  titleDescription: "Cloud Computing com Docker",
  description: [
    "Docker é uma Plataforma como Serviço (PaaS). Docker utiliza isolamento entre processos fazendo com que toda sua aplicação (seu código) e suas dependências fiquem isoladas em um Container virtual que pode ser executada em praticamente qualquer distribuição Linux, Windows e MacOS.",
    "Como Docker é super leve devido sua característica de compartilhar o mesmo Kernel, é possível com uma única máquina rode dezenas de aplicações, reduzindo e muito o desperdício de hardware e principalmente dinheiro.",
    "Docker é uma ferramenta importante quando você está criando a fundação para aplicações modernas habilitando fácil desenvolvimento para Computação em Nuvem e além disso, utilizar Docker faz com que o controle de suas aplicações sejam granulares"
  ],
  cardsTitle: "Conheça alguns (e excelentes) motivos de adotar Docker",
  resources: [
    {
      icon: "bx bx-run",
      title: "Agilidade",
      text: "Utilizando Containers (Docker) faz com que o deploy do seu app caia para literalmente segundos. Normalmente tinhamos que provisionar uma máquina inteira para que isso fosse atingido, não temos saudades desse tempo."
    },
    {
      icon: "bx bx-transfer",
      title: "Mobilidade",
      text: "Com Docker não existe limitação de qual ambiente está rodando ou desenvolvendo sua aplicação, tudo fica contido em uma única imagem, com isso não importa o SO que esteja utilizando, irá funcionar."
    },
    {
      icon: "bx bx-desktop",
      title: "Automação",
      text: "Toda infraestrutura necessária para rodar sua aplicação está contida em um único arquivo, o famoso Dockerfile. Concentre tudo o que seu app precisa para funcionar neste arquivo. Simples!."
    },
    {
      icon: "bx bx-box",
      title: "Escalabilidade",
      text: "Contrua sua aplicacão em pequenos segmentos, garantindo assim que em caso de problemas toda sua aplicação não irá cair junto. Replique seu app no cluster garantindo cada vez mais disponibilidade."
    },
  ]
}

export default function Docker() {
  return (
    <Tool
      data={data}
    />
  )
}
