import './Clients.css'

const imagePath = "assets/img"
const imagePathClients = imagePath + "/clients"

const Description = [
  {
    id: "sicredi",
    title: "Sicredi",
    link: 'https://sicredi.com.br',
    image: imagePathClients + "/sicredi.png",
    business: "Sicredi é a primeira instituição financeira cooperativa do Brasil fundada em 1902",
    case: true
  },
  {
    id: "jive",
    title: "Jive Software",
    link: 'https://jivesoftware.com',
    image: imagePathClients + "/jive.png",
    business: "Software de comunicação e colaboração localizada em Palo Alto na California",
    case: false
  },
  {
    id: "lit",
    title: "LIT",
    link: 'https://lit.com.br',
    image: imagePathClients + "/lit.png",
    business: "Método de ensino online com Inteligência Artificial que se adapta ao seu ritmo",
    case: true
  },
  {
    id: "saintpaul",
    title: "Saint Paul",
    link: 'https://saintpaul.com.br',
    image: imagePathClients + "/saintpaul.png",
    business: "Uma das melhores da America Latina e a escola de negócios mais digital do Brasil",
    case: true
  },
  {
    id: "dbserver",
    title: "DB Server",
    link: 'https://dbserver.com.br',
    image: imagePathClients + "/dbserver.png",
    business: "DBServer auxilia organizações a realizar transformações digitais promovendo a inovação",
    case: false
  },
  {
    id: "stefanini",
    title: "Stefanini",
    link: 'https://stefanini.com',
    image: imagePathClients + "/stefanini.png",
    business: "Empresa Brasileira multinacional provedora de serviços e criação de software",
    case: false
  },
  {
    id: "poatek",
    title: "Poatek",
    link: 'https://poatek.com',
    image: imagePathClients + "/poatek.png",
    business: "Consultoria em TI focada em construir software para clientes nacionais e internacionais",
    case: false
  },
  {
    id: "4linux",
    title: "4Linux",
    link: 'https://4linux.com.br',
    image: imagePathClients + "/4linux.png",
    business: "Prestadora de serviços nas áreas de Cursos, Consultoria e softwares baseados em Linux",
    case: false
  },
  {
    id: "clinia",
    title: "Clinia",
    link: 'https://clinia.io',
    image: imagePathClients + "/clinia.png",
    business: "Plataforma de saúde para profissionais que buscam gerenciamento de suas tarefas",
    case: false
  },
  {
    id: "agco",
    title: "AGCO",
    link: 'https://www.agco.com.br',
    image: imagePathClients + "/agco.png",
    business: "AGCO é uma empresa Americana de maquinários e manufaturas fundada em 1990",
    case: true
  },
  {
    id: "rb",
    title: "RB",
    link: 'https://rb.com.br',
    image: imagePathClients + "/rb.png",
    business: "Otimize e reduza gastos com vale transporte de sua empresa de forma inteligente.",
    case: true
  },
  {
    id: "4all",
    title: "4ALL",
    link: 'https://4all.com.br',
    image: imagePathClients + "/4all.png",
    business: "Tecnologias de Pagamentos e Fidelização, impulsionamos os resultados do seu negócio.",
    case: false
  }

]

export default function Clients() {
  return (
    <section id="services" className="services section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Nossos Clientes</h2>
        </div>

        <div className="row">
          {
            Description.map((attr, index) => {
              return (
                <div key={index} className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="icon-box apsis-client-card">
                    <div className="icon" style={{height: '120px', textAlign: 'center'}}>
                      <a href={attr.link} target="_blank" rel="noopener noreferrer">
                        <img src={attr.image} className="img-fluid" alt={attr.title} />
                      </a>
                    </div>
                    <p>{attr.business}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </section >

  )
}
