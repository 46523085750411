export default function Link({
  href,
  target = "_blank",
  rel = "noopener noreferrer",
  icon
}) {
  return (
    <a
      href={href}
      target={target}
      rel={rel}
    >

      {
        icon ?
          <i className={icon}></i>
          :
          null
      }
    </a>
  )
}
