import Image from "./Image"
import Link from "./Link"

export function Partner({ data }) {
  return (
    <>
      {
        data.map((attr, index) => {
          if (attr.active) {
            return (
              <div key={index} style={{ paddingTop: '10px' }} className="col-xxl-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12" data-bs-toggle="modal" data-bs-target={"#" + attr.id}>
                <div className="member d-flex align-items-start aos-init">
                  <div className="pic">
                    <Image
                      src={attr.avatar}
                      className="img-fluid rouded"
                      alt={attr.name} />
                  </div>

                  <div key={index} className="member-info">
                    <h5>{attr.name}</h5>
                    <h4>{attr.jobTitle}</h4>

                    <span>{attr.city}</span>

                    <p>{attr.jobDescription}</p>

                    <div className="social d-flex align-items-center">
                      <Link
                        href={`https://linkedin.com/in/${attr.links.linkedin}`}
                        icon="bx bxl-linkedin"
                      />

                      {
                        attr.links.gitlab ?
                          <Link
                            href={`https://gitlab.com/${attr.links.gitlab}`}
                            icon="bx bxl-gitlab"
                          />
                          : null
                      }

                      <Link
                        href={`https://github.com/${attr.links.github}`}
                        icon="bx bxl-github"
                      />

                      {
                        attr.links.youtube ?
                          <Link
                            href={`https://www.youtube.com/c/${attr.links.youtube}`}
                            icon="bx bxl-youtube"
                          />
                          : null
                      }

                      {
                        attr.links.stackoverflow ?
                          <Link
                            href={`https://stackoverflow.com/users/${attr.links.stackoverflow}`}
                            icon="bx bxl-stack-overflow"
                          />
                          : null
                      }

                      {
                        attr.links.twitter ?
                          <Link
                            href={`https://twitter.com/${attr.links.twitter}`}
                            icon="bx bxl-twitter"
                          />
                          : null
                      }

                    </div>
                  </div>
                </div>
              </div>
            )
          }
        })
      }
    </>
  )
}

export function PartnerModal({ data }) {
  return (
    <>
      {
        data.map((attr, index) => {
          if (attr.active) {
            return (
              <div key={index} className="modal fade" id={attr.id} data-bs-backdrop="static" tabIndex="-1"
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header team-modal-header">
                      <h5 className="modal-title w-100 text-center team-modal-title">{attr.name}</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                      <div className="card-team user-card">
                        <div className="card-team-block">
                          <div className="user-image">
                            <img src={attr.avatar} className="img-radius" alt={attr.name} />
                          </div>
                          <h6 className="f-w-600 m-t-25 m-b-10">{attr.jobTitle}</h6>
                          <p className="text-muted m-t-15">{attr.jobDescription}</p>
                          <hr></hr>

                          <div className="row justify-content-center user-social-link">
                            <div className="col-auto">
                              <Link
                                href={`https://linkedin.com/in/${attr.links.linkedin}`}
                                icon="bx bxl-linkedin"
                              />

                              {
                                attr.links.gitlab ?
                                  <Link
                                    href={`https://gitlab.com/${attr.links.gitlab}`}
                                    icon="bx bxl-gitlab"
                                  />
                                  : null
                              }

                              <Link
                                href={`https://github.com/${attr.links.github}`}
                                icon="bx bxl-github"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            )
          }
        })
      }
    </>
  )
}
