export default function Tool({ data, cardsEnabled = true }) {
  return (
    <main>
      <section id="breadcrumbs" className="breadcrumbs">
        <div className="container">

          <ol>
            <li><a href={"/"}>Início</a></li>
            <li>{data.title}</li>
          </ol>
          <h2>{data.title} | <strong>{data.subtitle}</strong></h2>

        </div>
      </section>

      <section className="inner-page section-bg">
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
              <h4 style={{ textTransform: 'uppercase' }}>{data.titleDescription}</h4>
              {
                data.description.map((description, index) => {
                  return (
                    <>
                      {
                        <p key={index}>{description}</p>
                      }
                    </>
                  )
                })
              }
            </div>

            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12" style={{ textAlign: 'center' }}>
              <p>
                <img src={"/assets/img/technologies/" + data.id + ".png"} alt={data.title} className="img-fluid" />
              </p>
            </div>
          </div>
        </div>
      </section>

      {
        cardsEnabled ?

          <section className="inner-page section">
            <div className="container">

              <div className="section-title" >
                <h4 style={{ textTransform: 'uppercase' }}>{data.cardsTitle}</h4>
              </div>

              <div className="row services">
                {
                  data.resources.map((attr, index) => {
                    return (
                      <>
                        <div key={index} className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 d-flex align-items-stretch">
                          <div className="icon-box">
                            <h4 className="icon"><i className={attr.icon}></i> {attr.title}</h4>
                            <p>{attr.text}</p>
                          </div>
                        </div>
                      </>
                    )
                  })
                }
              </div>
            </div>
          </section>
          : null
      }

    </main>
  )
}
