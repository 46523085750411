const imagePath = "assets/img"
const imagePathTech = imagePath + "/technologies"

const Tools = [
  {
    title: "Terraform | Automação de Infraestrutura",
    subtitle: "Infraestrutura em código",
    text: "Ferramenta de software de código para gerenciar centenas de serviços em nuvem. Codifica APIs de nuvem em arquivos de configuração declarativos.",
    link: 'technology/terraform',
    image: imagePathTech + "/terraform.png"
  },
  {
    title: "Kubernetes | Containers",
    subtitle: "Orquestração de Containers",
    text: "Orquestre todos seus containers com a plataforma nascida dentro do Google. Implemente e dimensione de forma rápida seus apps.",
    link: 'technology/kubernetes',
    image: imagePathTech + "/kubernetes.png"
  },
  {
    title: "Ansible | Gerência de configuração",
    subtitle: "Gerenciamento de Configuração",
    text: "Ferramenta de TI de código aberto para gerenciar, automatizar, configurar servidores e, implantar aplicativos, a partir de uma localização central.",
    link: 'technology/ansible',
    image: imagePathTech + "/ansible.png"
  },
  {
    title: "AWS | Amazon Web Services",
    subtitle: "Computação em Nuvem",
    text: "AWS é uma plataforma de serviços de computação em nuvem, que formam uma plataforma de computação na nuvem oferecida pela Amazon.com.",
    link: 'technology/aws',
    image: imagePathTech + "/aws.png"
  },
  {
    title: "Docker | Containers",
    subtitle: "Software em pacotes",
    text: "Docker mudou a forma que criamos software. Desenvolva aplicações em qualquer lugar e tenha todas dependências em uma única imagem.",
    link: 'technology/docker',
    image: imagePathTech + "/docker.png"
  },
  {
    title: "Gitlab | Entrega Contínua",
    subtitle: "Entregas contínuas do seu software",
    text: "Integração e entrega de contínua de Software. Garanta que o que está sendo desenvolvido está de fato em pleno funcionamento com entregas rápidas",
    link: 'technology/gitlab',
    image: imagePathTech + "/gitlab.png"
  },
]

export default function Technologies() {
  return (
    <>
      <section id="techs" className="team section-bg">
        <div className="container" data-aos="fade-up">

          <div className="section-title">
            <h2>Tecnologias</h2>
            <p>Atuamos com diversas tecnologias para aplicações modernas</p>
          </div>

          <div className="row">
            {
              Tools.map((attr, index) => {
                return (
                  <div key={index} className="col-lg-6 mt-4" style={{ cursor: 'pointer' }}>
                    <a href={attr.link} style={{ color: '#000' }}>
                      <div className="member d-flex align-items-start">
                        <div className="pic">
                            <img className="img-fluid" src={attr.image} alt={attr.title} />
                        </div>

                        <div className="member-info">
                          <h4>{attr.title}</h4>
                          <span>{attr.subtitle}</span>
                          <p style={{marginRight: '30px'}}>{attr.text}</p>
                          <div className="social"></div>
                          <p style={{textAlign: 'right', marginRight: '35px', fontSize: '13px'}}><a href={attr.link}>Saiba mais</a></p>
                        </div>
                      </div>
                    </a>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
    </>
  )
}
