function Services() {
  return (
    <section id="services" className="services section-bg" style={{ padding: '10px 0' }}>
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Serviços</h2>
          <p>Atuamos em algumas áreas, veja em qual delas podemos te ajudar.</p>
        </div>

        <div className="row">
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-book-open bx-lg"></i></div>
              <h4><a href="">Treinamentos</a></h4>
              <p>Temos uma ampla variedade de opções de treinamentos para a sua equipe se aperfeiçoar conquistando níveis de
                excelência no mercado</p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-user bx-lg"></i></div>
              <h4 className=""><a href="">Consultoria</a></h4>
              <p>Consultoria para empresas, supervisão da execução operacional de trabalhos e evolução nos processos de
                trabalho.</p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              <div className="icon"><i className="bx bxl-visual-studio bx-lg"></i></div>
              <h4><a href="">Desenvolvimento</a></h4>
              <p>Desenvolvimento de Sistemas Web em Frontend/Backend com implementação em CI/CD.</p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-code-alt bx-lg"></i></div>
              <h4><a href="">Infraestrutura</a></h4>
              <p>Soluções em Arquitetura em Cloud Computing e Infraestrutura em código com Cloudformation dentre outros.</p>
            </div >
          </div >
        </div >
      </div >
    </section >
  )
}

export default Services;
