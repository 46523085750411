function WhyUs() {
  return (
    <section id="whyus" className="whyus section-bg">
      <div className="container" data-aos="fade-up">

        <div className="row">
          <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
            <div className="content">
              <h3>Somos especialistas em soluções em <strong>Cloud Computing</strong></h3>
              <p>Atuando desde 2014 no segmento de Cloud Computing, trouxemos experiência de diversos projetos e com essa
                experiência conseguimos adequar melhor cada necessidade.
              </p>
            </div>

            <div className="accordion-list">
              <ul>
                <li>
                  <a data-bs-toggle="collapse" className="collapse" data-bs-target="#accordion-list-1"><span>01</span>Quer
                    entender se sua empresa necessita de Cloud Computing?<i className="bx bx-chevron-down icon-show"></i><i
                      className="bx bx-chevron-up icon-close"></i></a>
                  <div id="accordion-list-1" className="collapse show" data-bs-parent=".accordion-list">
                    <p>
                      Seguimos um modelo em Canvas que nos faz entender melhor qual sua necessidade, se é o momento de
                      migrar para Cloud e ainda por onde começar.
                    </p>
                  </div>
                </li>

                <li>
                  <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" className="collapsed"><span>02</span>
                    Com receio de encontrar barreiras nesse novo mundo? <i className="bx bx-chevron-down icon-show"></i><i
                      className="bx bx-chevron-up icon-close"></i></a>
                  <div id="accordion-list-2" className="collapse" data-bs-parent=".accordion-list">
                    <p>
                      Entendemos como o mundo de Cloud Computing é imenso, cada provedor de Cloud Computing tem suas
                      terminologias, serviços e também suas limitações. Podemos te ajudar a encontrar a melhor solução para
                      seu produto e é claro o seu bolso.
                    </p>
                  </div>
                </li>

                <li>
                  <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="collapsed"><span>03</span>
                    <i className="bx bx-chevron-down icon-show"></i>Treinamento para colaboradores?<i
                      className="bx bx-chevron-up icon-close"></i></a>
                  <div id="accordion-list-3" className="collapse" data-bs-parent=".accordion-list">
                    <p>
                      Criamos laboratórios e documentação sob medida para seus colaboradores.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <img className="mx-auto d-block img-fluid" src="assets/img/page/whyus.svg" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyUs;
