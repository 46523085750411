import './Courses.css'

const imagePath = "assets/img"
const imagePathTech = imagePath + "/technologies"

const Tools = [
  {
    title: "Terraform | Automação de Infraestrutura",
    subtitle: "Infraestrutura em código",
    text: "Ferramenta de software de código para gerenciar centenas de serviços em nuvem. Codifica APIs de nuvem em arquivos de configuração declarativos.",
    link: 'courses/terraform',
    image: imagePathTech + "/terraform.png"
  },
  {
    title: "Ansible | Gerencia de Infra",
    subtitle: "Infraestrutura em código",
    text: "Ferramenta de software de código para gerenciar centenas de serviços em nuvem. Codifica APIs de nuvem em arquivos de configuração declarativos.",
    link: 'courses/terraform',
    image: imagePathTech + "/ansible.png"
  },
  {
    title: "Kubernetes | Gerencia de Infra",
    subtitle: "Infraestrutura em código",
    text: "Ferramenta de software de código para gerenciar centenas de serviços em nuvem. Codifica APIs de nuvem em arquivos de configuração declarativos.",
    link: 'courses/terraform',
    image: imagePathTech + "/kubernetes.png"
  }
]

export default function Courses() {
  return (
    <>
      <section id="techs" className="team section-bg" style={{ marginTop: '70px' }}>
        <div className="container" data-aos="fade-up">

          <div className="section-title">
            <h2>Nossos Cursos</h2>
          </div>

          <div className="row">
            {
              Tools.map((attr, index) => {
                return (
                  <div key={index} className="col-lg-6 mt-4" style={{ cursor: 'pointer' }}>
                    <a href={attr.link} style={{ color: '#000' }}>
                      <div className="member d-flex align-items-start">
                        <div className="pic">
                          <img className="img-fluid" src={attr.image} alt={attr.title} />
                        </div>

                        <div className="member-info">
                          <h4>{attr.title}</h4>
                          <span>{attr.subtitle}</span>
                          <p style={{ marginRight: '30px' }}>{attr.text}</p>
                          <div className="social"></div>
                          <p style={{ textAlign: 'right', marginRight: '35px', fontSize: '13px' }}><a href={attr.link}>Conheça a grade</a></p>
                        </div>
                      </div>
                    </a>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
    </>
  )
}
