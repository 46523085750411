const TitleText = "Vamos conversar"

const Text = [
  "Para sermos reconhecidos pela agilidade e pela efetividade na resposta aos nossos parceiros, buscamos ser facilitadores, comprometendo-nos, cuidando das pessoas e entregando resultados aos clientes. Certo, agora de que forma podemos ajudar o seu negócio?",
]

function LetsTalk() {
  return (
    <section id="cta" className="cta">
      <div className="container" data-aos="zoom-in">
        <div className="row">
          <div className="col-lg-9 text-lg-start">
            <h3>{TitleText}</h3>
            {
              Text.map((text => {
                return (
                  <p>{text}</p>
                )
              }))
            }

          </div>
          <div className="col-lg-3 cta-btn-container text-center">
            <a className="cta-btn align-middle" >{TitleText}</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LetsTalk;
